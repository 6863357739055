import React, { useState, useEffect } from "react";
import "./App.css";
import GameBoard from "./components/GameBoard";
// In index.js or App.js
import "./index.css"; // or './App.css'
import Button from "@mui/material/Button";
import ReactGA from "react-ga";
import InstructionModal from "./components/InstructionModal";

const TRACKING_ID = "G-SKZFESEG18"; // Replace with your tracking ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const App: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setIsModalOpen(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="App">
      <InstructionModal isOpen={isModalOpen} onClose={handleCloseModal} />
      <GameBoard />
    </div>
  );
};

export default App;