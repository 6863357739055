// LivesDisplay.tsx

import React from "react";
import "./LivesDisplay.css";

interface LivesDisplayProps {
  lives: number;
  totalLives: number;
}

const LivesDisplay: React.FC<LivesDisplayProps> = ({ lives, totalLives }) => {
  const renderLives = () => {
    const dots = [];
    for (let i = 0; i < totalLives; i++) {
      // Filled dot for a remaining life, greyed-out for a lost life
      const dotStyle = i < lives ? "filledDot" : "emptyDot";
      dots.push(<span key={i} className={dotStyle} />);
    }
    return dots;
  };

  return <div className="livesDisplay">Gjenstående feil: {renderLives()}</div>;
};

export default LivesDisplay;
