import React from "react";
import Button from "@mui/material/Button";
import seedrandom from "seedrandom";

interface WordGridProps {
  words: string[];
  onSelectWord: (word: string) => void;
  gameOver: boolean; // Prop to indicate if the game is over
  playerSelections: string[]; // New prop to hold the player's selections
}

const getDailySeed = () => {
  const today = new Date();
  return (
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );
};

const shuffleArray = (array: string[], seed: string): string[] => {
  const rng = seedrandom(seed);
  const arrayCopy = [...array];
  let currentIndex = arrayCopy.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(rng() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [arrayCopy[currentIndex], arrayCopy[randomIndex]] = [
      arrayCopy[randomIndex],
      arrayCopy[currentIndex],
    ];
  }

  return arrayCopy;
};

const WordGrid: React.FC<WordGridProps> = ({
  words,
  onSelectWord,
  gameOver,
  playerSelections,
}) => {
  const isSmallScreen = window.innerWidth <= 480; // Define breakpoint for small screen
  const dailySeed = getDailySeed();
  const shuffledWords = React.useMemo(
    () => shuffleArray(words, dailySeed),
    [words, dailySeed]
  );

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "10px",
        padding: "10px",
      }}
    >
      {shuffledWords.map((word, index) => (
        <Button
          variant="contained"
          color={playerSelections.includes(word) ? "secondary" : "primary"} // Change color based on selection
          key={index}
          onClick={() => onSelectWord(word)}
          disabled={gameOver}
          className="word-button" // Add this line
          style={{
            padding: isSmallScreen ? "5px" : "10px", // Smaller padding for small screens
            fontSize: isSmallScreen ? "12px" : "16px", // Smaller font size for small screens
            opacity: gameOver ? 0.5 : 1,
            cursor: gameOver ? "not-allowed" : "pointer",
          }}
        >
          {word}
        </Button>
      ))}
    </div>
  );
};

export default WordGrid;