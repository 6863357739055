// CategoryDisplay.tsx

import React from "react";

interface CategoryDisplayProps {
  category: string;
}

const CategoryDisplay: React.FC<CategoryDisplayProps> = ({ category }) => {
  return (
    <div
      style={{
        background: "#FFEEEED0",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        textAlign: "center",
        padding: "10px",
        fontSize: "12px",
        fontWeight: "bold",
        border: "1px solid black",
        borderBottom: "none", // Remove the bottom border to merge visually with the words below
      }}
    >
      {category}
    </div>
  );
};

export default CategoryDisplay;
