// CorrectGuessesDisplay.tsx

import React from "react";
import CategoryDisplay from "./CategoryDisplay"; // Ensure this path is correct based on your project structure

const COLORS = ['#f5dc6e', '#9db959', '#a9bae8', '#a879ba']; // Example colors

interface CorrectGuessesDisplayProps {
  correctWords: string[];
  category: string;
  index: number; // Added index to determine which color to use
}

const CorrectGuessesDisplay: React.FC<CorrectGuessesDisplayProps> = ({
  correctWords,
  category,
  index,
}) => {
  const backgroundColor = COLORS[index % COLORS.length]; // Determine the background color based on index
  return (
    <>
      <CategoryDisplay category={category} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "0px",
          padding: "0",
          borderTop: "none",
          marginBottom: "20px",
          backgroundColor, // Use the same background color for the whole set
        }}
      >
        {correctWords.map((word, wordIndex) => (
          <div
            key={wordIndex}
            style={{
              padding: "10px",
              border: "1px solid black",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            {word}
          </div>
        ))}
      </div>
    </>
  );
};

export default CorrectGuessesDisplay;
