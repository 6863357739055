import React from 'react';

interface WordContainerProps {
  selections: string[];
}

const WordContainer: React.FC<WordContainerProps> = ({ selections }) => {
  // Temporarily disable the component
  return null;

  /*
  const isSmallScreen = window.innerWidth <= 480; // Or any other breakpoint you prefer

  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '20px' }}>
      {selections.map((selection, index) => (
        <div
          key={index}
          style={{
            padding: isSmallScreen ? '5px' : '10px', // Adjust padding for small screens
            border: '10px solid black',
            minWidth: isSmallScreen ? '80px' : '100px', // Adjust minimum width for small screens
            textAlign: 'center',
            fontSize: isSmallScreen ? '14px' : '16px', // Optional: Adjust font size for small screens
          }}
        >
          {selection}
        </div>
      ))}
    </div>
  );
  */
};

export default WordContainer;