// gameStats.ts

// Initialize state variables for score and lives
let score = 0;
let lives = 4; // Start with 4 lives

// Function to get the current score
export const getScore = () => score;

// Function to increment the score
export const incrementScore = () => {
  score += 1;
};

// Function to get the remaining lives
export const getLives = () => lives;

// Function to decrement the lives
export const decrementLives = () => {
  if (lives > 0) {
    lives -= 1;
  }
};

// Function to check if the game is over (when lives reach 0)
export const isGameOver = () => lives <= 0;

// Function to reset the game stats
export const resetGameStats = () => {
  score = 0;
  lives = 4;
};