// wordutils.ts
// Assuming this is the structure of each set within your dailySets array
interface WordSet {
  words: string[];
  category: string; // If each set includes a category or similar attribute
}

// If dailySets is an array of days, and each day contains an array of sets:
interface DailySet {
  day: number;
  sets: WordSet[];
}

// Assume DailySet and WordSet interfaces are defined above, similar to previous examples

// In wordutils.ts
export const prepareWordsForGame = async () => {
    const dailySets = await fetchWordSets();
    const dayNumber = new Date().getDate(); // Adjust as needed
    const todaySet = dailySets.find(set => set.day === dayNumber);

    if (!todaySet) {
        console.error("No set found for today.");
        //print what we searched for
        console.log("Day number:", dayNumber);
        console.log("Daily sets loaded:", dailySets);
        return { preparedWords: [], todaySet: null };
    }

    const preparedWords = todaySet.sets.flatMap((set, index) =>
        set.words.map(word => ({ word, setId: index }))
    );

    return { preparedWords, todaySet }; // Return both prepared words and the set for matching
};

// Adjust the return type of fetchWordSets to Promise<DailySet[]> to reflect the expected structure
export const fetchWordSets = async (): Promise<DailySet[]> => {
  try {
    //path is project/public/words.json
    const response = await fetch(`${process.env.PUBLIC_URL}/words.json`);
    console.log("Response:", response);
    const data = await response.json();
    return data.dailySets;
  } catch (error) {
    //print path to check if it is correct
    console.error("Error fetching word sets:", error);
    return [];
  }
};

export const getWordSetsForToday = async () => {
  const dailySets = await fetchWordSets();
  const dayNumber = new Date().getDate(); // Simplistic approach; adjust as needed
  const todaySet = dailySets.find((set: DailySet) => set.day === dayNumber);

  console.log("Day number:", dayNumber);
  console.log("Daily sets loaded:", dailySets);
  console.log("Today's set found:", todaySet);

  if (todaySet) {
    // Explicitly typing the accumulator as string[] to avoid type inference issues
    const allWordsForToday = todaySet.sets.reduce<string[]>(
      (acc, currentSet) => {
        return acc.concat(currentSet.words); // Concatenate the words from each set
      },
      []
    );

    return allWordsForToday; // This now returns an array of all words for today from all sets
  } else {
    return null;
  }
};
