// wordSetMatcher.ts

// Interfaces to represent the structure of your sets and words
interface WordSet {
  words: string[];
  category: string;
}

interface DailySet {
  day: number;
  sets: WordSet[];
}

// Function to find the category based on selected words
export const findCategoryForWords = (
  selectedWords: string[],
  dailySets: DailySet[]
): string | null => {
  for (const dailySet of dailySets) {
    for (const set of dailySet.sets) {
      // Check if every selected word is in the current set's words
      if (selectedWords.every((word) => set.words.includes(word))) {
        return set.category; // Return the category if all selected words are found
      }
    }
  }
  return null; // Return null if no matching set is found
};
