import React from "react";

interface InstructionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InstructionModal: React.FC<InstructionModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "LightGreen",
          padding: "20px",
          borderRadius: "5px",
          maxWidth: "600px",
          width: "100%",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        }}
      >
        <h2>Finn forbindelser!</h2>
        <p>Finn grupper med fire ting som har noe til felles</p>
        <p>
          Velg fire ting og trykk "Velg!" for å sjekke om svaret ditt er riktig.
          Finn gruppene uten å gjøre 4 feil!
        </p>
        <h3>Eksempler</h3>
        <p>
          <strong>Fisk:</strong> Laks, Torsk, Makrell, Stør
        </p>
        <p>
          <strong>Is ___:</strong> Bad, Bit, Bil, Bre
        </p>
        <p>
          Kategoriene er alltid mer spesifikke enn "Ord på fem bokstaver", "Navn"
          eller "Verb"
        </p>
        <p>
          Hvert puslespill har kun én løsning. Se opp for ord som kan passe i flere kategorier!
          
          Nytt spill hver søndag :D
        </p>
        <button onClick={onClose} style={{ float: "right", marginTop: "20px" }}>
          Lukk
        </button>
      </div>
    </div>
  );
};

export default InstructionModal;
