import React, { useState, useEffect } from "react";
import GameControls from "./GameControls";
import WordGrid from "./WordGrid";
import WordContainer from "./WordContainer"; // Assuming you have this component
import CorrectGuessesDisplay from "./CorrectGuessesDisplay";
import { prepareWordsForGame } from "./lib/wordutils";
import { findCategoryForWords } from "./lib/wordSetMatcher"; // Adjust the path as needed
import {
  loadCorrectGuesses,
  saveCorrectGuesses,
  clearSavedGuesses,
} from "./lib/persistenceUtils"; // Adjust the path as needed
import { clear } from "console";
import {
  decrementLives,
  getLives,
  getScore,
  incrementScore,
  isGameOver,
  resetGameStats,
} from "./lib/gamestats"; // Adjust the path as needed
import LivesDisplay from "./LivesDisplay"; // Adjust path as needed
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ConfettiExplosion from "confetti-explosion-react";


// Define the CorrectGuess interface outside of the component to avoid re-declaration on each render
interface CorrectGuess {
  words: string[];
  category: string;
}

interface WordSet {
  words: string[];
  category: string;
}

interface DailySet {
  day: number;
  sets: WordSet[];
}

const GameBoard: React.FC = () => {
  const [currentSetIndex, setCurrentSetIndex] = useState(0); // Added to keep track of the current day/set index
  // Update the state to hold an array of objects, each with `word` and `setId`
  const [availableWords, setAvailableWords] = useState<
    { word: string; setId: number }[]
  >([]);

  const [playerSelections, setPlayerSelections] = useState<string[]>([]);
  const [correctGuesses, setCorrectGuesses] = useState<CorrectGuess[]>([]);
  const [todaySet, setTodaySet] = useState<DailySet | null>(null);
  const [lives, setLives] = useState(getLives());
  const isSelectionClear = playerSelections.length === 0;
  const [confettiTrigger, setConfettiTrigger] = useState(false);

  // Inside your GameBoard component, before the return statement
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  interface CorrectGuess {
    words: string[];
    category: string;
  }

  const handleSelectWord = (word: string) => {
    console.log("Selecting word:", word); // Debug line
    setPlayerSelections((currentSelections) => {
      if (currentSelections.includes(word)) {
        // Word is already selected, remove it
        return currentSelections.filter(
          (selectedWord) => selectedWord !== word
        );
      } else {
        // Word is not selected, add it if less than 4 selections
        const newSelections = [...currentSelections, word];
        return newSelections.slice(0, 4); // Ensure no more than 4 selections
      }
    });
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  useEffect(() => {
    // Check if there's a saved state for today and update accordingly
    const savedGuesses = loadCorrectGuesses();
    if (savedGuesses) {
      // Assuming loadCorrectGuesses() now also restores the score and lives
      setCorrectGuesses(savedGuesses); // Fix: Access the correct property name for saved guesses
      setLives(getLives());
      // Trigger re-render if score from saved state equals 4
      if (getScore() === 4) {
        setConfettiTrigger(true);
      }
    }
  }, []);

  // In GameBoard.tsx
  useEffect(() => {
    const initializeGame = async () => {
      const remainingLives = getLives();
      const { preparedWords, todaySet } = await prepareWordsForGame();
      setAvailableWords(preparedWords);
      setTodaySet(todaySet); // Assume you have a state [todaySet, setTodaySet]
      const savedGuesses = loadCorrectGuesses();
      if (savedGuesses) {
        setCorrectGuesses(savedGuesses);
        setPlayerSelections([]);
      }
      setAvailableWords((words) =>
        words.filter(
          (word) =>
            !savedGuesses?.some((guess) => guess.words.includes(word.word))
        )
      );
    };
    initializeGame();
  }, []);

  const resetGame = async () => {
    // Clear current selections and correct guesses
    setPlayerSelections([]);
    setCorrectGuesses([]);
    setCorrectWords([]);
    //setAvailableWords(await prepareWordsForGame());
    const { preparedWords, todaySet } = await prepareWordsForGame();
    setAvailableWords(preparedWords);
    setCorrectGuesses([]);
    clearSavedGuesses();
    resetGameStats();
  };

  const [correctWords, setCorrectWords] = useState<string[]>([]);

  const clearSelections = () => {
    setPlayerSelections([]);
  };

  const handleSubmit = () => {
    if (playerSelections.length === 4 && todaySet) {
      // Trigger the shake animation
      const shakeButtons = () => {
        const buttons = document.querySelectorAll('.MuiButton-containedSecondary');
        buttons.forEach(button => {
          button.classList.add('shake-animation');
          setTimeout(() => button.classList.remove('shake-animation'), 1000);
        });
      };

      shakeButtons();

      // Delay the rest of the logic by 1 second to allow the shake animation to complete
      setTimeout(() => {
        const category = findCategoryForWords(playerSelections, [todaySet]);
        if (category) {
          const newCorrectGuesses = [
            ...correctGuesses,
            { words: playerSelections, category },
          ];
          incrementScore();
          const newScore = getScore();
          if (newScore === 4) {
            setConfettiTrigger(true);
          }

          // Correct guess, since a category was found
          const newCorrectGuess: CorrectGuess = {
            words: playerSelections,
            category: category, // Use the found category
          };
          console.log("Correct guess. Category:", category);

          // Add to correct guesses and update state accordingly
          setCorrectGuesses((prevGuesses) => [...prevGuesses, newCorrectGuess]);
          saveCorrectGuesses(newCorrectGuesses);

          // Update available words and clear selections as needed
          setAvailableWords((words) =>
            words.filter((word) => !playerSelections.includes(word.word))
          );
          setPlayerSelections([]);
        } else {
          decrementLives();
          console.log("Incorrect. Selections do not belong to the same set.");
          
          setLives(getLives()); // Update local state to trigger rerender
          if (isGameOver()) {
            console.error("Game over!");
            setSnackbarMessage("Spillet er slutt!");
            setSnackbarOpen(true);
            // show all correct guesses since game is over
            if (todaySet) {
              setTimeout(() => {
                const remainingCorrectSets = todaySet.sets.filter(set => 
                  !correctGuesses.some(guess => 
                    guess.words.every(word => set.words.includes(word))
                ));

                const revealSet = (index: number) => {
                  if (index < remainingCorrectSets.length) {
                    setCorrectGuesses(prevGuesses => [
                      ...prevGuesses,
                      {
                        words: remainingCorrectSets[index].words,
                        category: remainingCorrectSets[index].category
                      }
                    ]);
                    setTimeout(() => revealSet(index + 1), 1000);
                  } else {
                    setAvailableWords([]);  // Clear remaining available words
                  }
                };

                setTimeout(() => revealSet(0), 2000);
              }, 2000);
            }
          }
          else {
            setSnackbarMessage("Feil svar, du mistet ett liv!");
            setSnackbarOpen(true);
          }
        }
      }, 1000);
    } else {
      console.log("Please select exactly 4 words.");
      // Handle case where the incorrect number of words is selected
      setSnackbarMessage("Vennligst velg 4 ord.");
      setSnackbarOpen(true);
    }
  };

  return (
    <div
      style={{
        maxWidth: "800px", // Adjust this value as needed
        margin: "0 auto", // Centers the div
        padding: "20px", // Adds some padding around the content
      }}
    >
      <div>
        {correctGuesses.map((guess, index) => (
          <CorrectGuessesDisplay
            key={index}
            correctWords={guess.words}
            category={guess.category}
            index={index}
          />
        ))}

        <WordGrid
          words={availableWords.map((item) => item.word)}
          onSelectWord={handleSelectWord}
          gameOver={isGameOver()}
          playerSelections={playerSelections}
        />
        <WordContainer selections={playerSelections} />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        {confettiTrigger && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
            }}
          >
            <ConfettiExplosion />
          </div>
        )}
        <LivesDisplay lives={getLives()} totalLives={4} />
        <GameControls
          onReset={resetGame}
          onClearSelections={clearSelections}
          onSubmit={handleSubmit}
          gameOver={isGameOver()}
          isSelectionClear={isSelectionClear}
        />
      </div>
    </div>
  );
};

export default GameBoard;