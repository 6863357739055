import React from "react";
import Button from "@mui/material/Button";

interface GameControlsProps {
  onReset: () => void;
  onClearSelections?: () => void;
  onSubmit?: () => void; // New prop for submission
  gameOver?: boolean; // New prop to indicate if the game is over
  isSelectionClear?: boolean; // Optional prop to indicate if selections are clear
}

const GameControls: React.FC<GameControlsProps> = ({
  onReset,
  onClearSelections,
  onSubmit,
  gameOver,
  isSelectionClear,
}) => (
  <div>
    {/* <Button variant="contained" onClick={onReset}>
      Restart Game
    </Button> */}
    {onClearSelections && (
      <Button
        variant="contained"
        onClick={onClearSelections}
        disabled={isSelectionClear} // Disable the button if the game is over
        sx={{
          ml: 1,
          bgcolor: "green.200",
          "&:hover": { bgcolor: "green.400" },
          opacity: isSelectionClear ? 0.5 : 1, // Make the button look disabled (optional)
          cursor: isSelectionClear ? 'not-allowed' : 'pointer', // Change cursor for disabled buttons (optional)
        }} // Use the sx prop for styling
        
      >
        
        Fjern markeringer
      </Button>
    )}
    {onSubmit && (
      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={gameOver} // Disable the button if the game is over
        sx={{
          ml: 1,
          bgcolor: "green.500",
          "&:hover": { bgcolor: "green.700" },
          opacity: gameOver ? 0.5 : 1, // Make the button look disabled (optional)
          cursor: gameOver ? 'not-allowed' : 'pointer', // Change cursor for disabled buttons (optional)
        }} // Use the sx prop for styling
      >
        Velg!
      </Button>
    )}
  </div>
);

export default GameControls;
