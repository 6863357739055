// persistenceUtils.ts
interface CorrectGuess {
    words: string[];
    category: string;
  }

export const saveCorrectGuesses = (guesses: CorrectGuess[]): void => {
  const dataToStore = {
    date: new Date().toDateString(), // Store only the date part to reset at midnight
    guesses: guesses,
  };
  localStorage.setItem('correctGuesses', JSON.stringify(dataToStore));
};

export const loadCorrectGuesses = (): CorrectGuess[] | null => {
  const storedData = localStorage.getItem('correctGuesses');
  if (storedData) {
    const { date, guesses } = JSON.parse(storedData);
    if (date === new Date().toDateString()) {
      return guesses as CorrectGuess[]; // Cast the parsed data back to the correct type
    } else {
      localStorage.removeItem('correctGuesses'); // Clear outdated guesses
    }
  }
  return null; // If there's no stored data, or it's outdated, return null
};

export const clearSavedGuesses = (): void => {
  localStorage.removeItem('correctGuesses');
};
